import approved from "./allowed.json";

const clients = {
    "myuserv1-web":"https://my.communityboss.app/"
}

export function isUrlApproved(url) {
    if(typeof url == "string") url = new URL(url);
    return !!url && !!approved.find(item => {
        if(url.hostname == item) return true;
        if(url.hostname.endsWith("."+item)) return true;
        return false;
    });
}