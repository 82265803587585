<script>
    import { onMount, tick } from "svelte";
    import { fetchEmailPasswordToken as update } from "../api";
    import { route } from "../util/router";

    export let email = null;
    export let token = null;
    export let password = null;

    // let emailInput;
    // let pwInput;

    let form = null;

    let submittable = false;
    let submitting = false;

    let error = null;
    let success = null;

    function checkSubmittable(form) {
        return submittable = !!(form && form.checkValidity && form.checkValidity());
    }

    function change(e) {
        return submittable = checkSubmittable(e.target.form || e.target);
    }

    async function submit(e) {

        e.preventDefault();

        if(submitting) return;

        const form = e.target;

        if(!checkSubmittable(form)) return;

        submitting = true;
        success = error = null;

        var json = await update(form.elements["email"].value, form.elements["password"].value);

        //console.log("result=", json);

        

        // handle success
        if(json.token) {

            token = json.token;
            return;
        }

        submitting = false;

        // handle errors
        if(!json.token) {
            if(json.status === 401) error = "Sorry, this password doesn't match";
            else if(json.status === 404) error = "Sorry, we don't recognize this email";
            else error = "Whoops, something went wrong";
            return;
        }

    }

    // run when form attached
    // $: if(form) {
    //     checkSubmittable(form);
    //     form.blur();
    // }

    // async function checkEmailPasswordAutofocus() {
    //     await tick();
    //     if(!email && !emailInput.value) emailInput.focus();
    //     else if(!pwInput.value) pwInput.focus();
    // }

    // $: if((emailInput && pwInput) || email) checkEmailPasswordAutofocus();

    onMount(async () => {
        await tick();
        submittable = checkSubmittable(form);
        //form.blur();
    });
</script>
<form bind:this={form} on:blur={change} on:change={change} on:input={change} on:blur={change} on:submit={submit}>
    <fieldset>
        <!-- <input type="hidden" name="email" value="{email || ""}" /> -->
        <ul>
            <li>
                <!-- <label for="login-combo-email">Password</label> -->
                <input id="login-email" type="email" name="email" readonly="readonly" spellcheck="false" required="required" value={email} placeholder="Enter your login email">
                <a href="/login">change</a>
            </li>
            <li>
                <!-- <label for="login-combo-email">Password</label> -->
                <input id="login-password" type="password" name="password" spellcheck="false" required="required" autocomplete="current-password" placeholder="Enter your password" value="{password||""}">
                <a href="/login/forgot?login_hint={escape(encodeURIComponent(email))}">forgot?</a>
            </li>
        </ul>
    </fieldset>
    {#if error}
        <p class="error">{error}</p>
        {/if}
    <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Logging in…" : "Log In" }</button>
        
    </footer>
</form>