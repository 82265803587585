<script>
</script>

<aside class="highlight">
  <h1>Welcome to Community&nbsp;Boss!</h1>
  <p>
    Parking&nbsp;Boss, Amenity&nbsp;Boss, and Luminous&nbsp;Residential are now
    part of Community&nbsp;Boss.
  </p>
  <p>
    Don’t worry, we’re the same&nbsp;company, same&nbsp;team, and same products,
    just with a new name. And we've got some cool new stuff in the works!
  </p>
  <p>
    Learn more at <a href="https://communityboss.com" target="_blank"
      >communityboss.com</a
    >
  </p>
</aside>
